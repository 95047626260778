<mat-toolbar class="ftx-dialog-toolbar" color="primary">
  <mat-icon fontSet="mdi" fontIcon="mdi-calendar-plus"></mat-icon>
  <h3 class="text-lg font-semibold pl-1">
    {{ "SCHEDULE_EXAM" | translate }}
  </h3>

  <span class="fx-grow-1"></span>
  <button mat-icon-button matDialogClose="null" tabindex="-1">
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
  </button>
</mat-toolbar>

<div class="fx-dialog-content" style="padding: 8px !important">
  <mat-accordion [multi]="true">
    <mat-expansion-panel [expanded]="true" [formGroup]="patientForm">
      <mat-expansion-panel-header tabindex="-1">
        <mat-panel-title>
          <mat-icon fontIcon="mdi-account-injury" fontSet="mdi"></mat-icon>
          {{ "PATIENT_EXAM" | translate }}
        </mat-panel-title>
        <mat-panel-description>
          {{
            (patientForm.get("lastName")?.value || "") +
              " " +
              (patientForm.get("firstName")?.value || "")
          }}
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="fx-layout-row fx-content-space-between fx-gap-8">
        <div class="fx-grow-1">
          <div class="flex flex-row justify-start items-start gap-1">
            <mat-form-field style="width: 180px">
              <mat-label>{{ "PATIENT_ID" | translate }}</mat-label>
              <input
                [placeholder]="'PATIENT_ID' | translate"
                [required]="
                  generalSetting &&
                  generalSetting.patientIdGenerationMode === 'MANUAL'
                "
                formControlName="externalPatientID"
                matInput
                tabindex="-1"
              />
            </mat-form-field>

            <div class="select-with-suffix">
              <mat-form-field style="width: 200px">
                <mat-label>{{ "TITLE" | translate }}</mat-label>
                <mat-select
                  [placeholder]="'TITLE' | translate"
                  formControlName="titleId"
                  tabindex="-1"
                >
                  @for (title of titles; track $index) {
                  <mat-option [value]="title.id">{{ title.value }}</mat-option>
                  }
                </mat-select>
                <button
                  (click)="$event.stopImmediatePropagation()"
                  tabindex="-1"
                  mat-icon-button
                  matIconSuffix
                >
                  <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
                </button>
              </mat-form-field>
            </div>

            <mat-form-field class="fx-grow-1">
              <mat-label>{{ "LAST_NAME" | translate }}</mat-label>
              <input
                (blur)="upperCase($event, 'lastName')"
                [placeholder]="'LAST_NAME' | translate"
                formControlName="lastName"
                matInput
                required
                type="text"
              />
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{ "FIRST_NAME" | translate }}</mat-label>
              <input
                (blur)="capitalize($event, 'firstName')"
                [placeholder]="'FIRST_NAME' | translate"
                formControlName="firstName"
                matInput
                required
              />
            </mat-form-field>

            @if (!data?.patient && !updating) {
            <button (click)="findPatient()" mat-icon-button>
              <mat-icon
                fontIcon="mdi-magnify"
                fontSet="mdi"
                aria-hidden="false"
                [matBadgeHidden]="
                  patientSelected || numOfDuplicatePatients === 0
                "
                [matBadge]="numOfDuplicatePatients"
                matBadgeColor="warn"
              ></mat-icon>
            </button>
            }
          </div>
          <div class="fx-layout-row fx-content-start fx-items-start fx-gap-4">
            <mat-form-field style="width: 180px">
              <mat-label>{{ "DATEOFBIRTH" | translate }}</mat-label>
              <input
                [matDatepicker]="dobPicker"
                (dateInput)="changeAge()"
                formControlName="dateOfBirth"
                matInput
                placeholder="{{ 'DATEOFBIRTH' | translate }}"
              />
              <mat-datepicker-toggle
                [for]="dobPicker"
                matSuffix
              ></mat-datepicker-toggle>
              <mat-datepicker
                #dobPicker
                (closed)="changeAge()"
              ></mat-datepicker>
            </mat-form-field>

            <div
              [formGroup]="ageForm"
              class="fx-layout-row fx-content-start fx-gap-4"
            >
              <mat-form-field style="width: 80px">
                <mat-label>{{ "AGE_YEARS" | translate }}</mat-label>
                <input
                  (keyup)="changeDate()"
                  formControlName="years"
                  matInput
                  placeholder="{{ 'AGE_YEARS' | translate }}"
                  type="number"
                />
              </mat-form-field>

              <mat-form-field style="width: 80px">
                <mat-label>{{ "AGE_MONTHS" | translate }}</mat-label>
                <input
                  (keyup)="changeDate()"
                  formControlName="months"
                  matInput
                  placeholder="{{ 'AGE_MONTHS' | translate }}"
                  type="number"
                />
              </mat-form-field>
            </div>

            <div class="fx-layout-column-nowrap relative" style="top: -8px">
              <h5
                style="margin: 0 8px"
                [style.color]="
                  patientForm.get('genderId').invalid ? '#cc4040' : ''
                "
              >
                {{ "GENDER" | translate }} *
              </h5>

              <mat-radio-group
                formControlName="genderId"
                class="fx-layout-row-nowrap fx-content-start fx-items-end"
              >
                @for (option of genders; track $index) {
                <mat-radio-button
                  [value]="option.id"
                  class="fx-grow-1"
                  [required]="true"
                >
                  {{
                    option.value === "U"
                      ? ("UNKNOWN" | translate)
                      : option.value
                  }}
                </mat-radio-button>
                }
              </mat-radio-group>
            </div>

            <span class="fx-grow-1"></span>

            <div
              class="fx-layout-column-nowrap fx-content-space-between relative"
              style="top: -8px"
            >
              <h5 style="margin: 0 8px">
                {{ "CONFIDENTIALITY" | translate }}
              </h5>

              <mat-radio-group
                formControlName="confidentialityId"
                class="fx-layout-row fx-content-start fx-items-end"
              >
                @for (option of confidentialities; track $index) {
                <mat-radio-button [value]="option.id" class="fx-grow-1">
                  {{ option.description }}
                </mat-radio-button>
                }
              </mat-radio-group>
            </div>
          </div>
        </div>
      </div>

      <div class="fx-layout-row fx-content-start fx-items-end fx-gap-4">
        <mat-form-field style="width: 180px">
          <mat-label>{{ "CIN" | translate }}</mat-label>
          <input
            matInput
            [placeholder]="'CIN' | translate"
            formControlName="cin"
          />
        </mat-form-field>
        <mat-form-field style="width: 200px">
          <mat-label>{{ "PHONE" | translate }}</mat-label>
          <input
            [placeholder]="'PHONE' | translate"
            formControlName="phone"
            matInput
            type="tel"
          />
        </mat-form-field>
        <mat-form-field class="fx-grow-1">
          <mat-label>{{ "EMAIL" | translate }}</mat-label>
          <input
            type="email"
            matInput
            [placeholder]="'EMAIL' | translate"
            formControlName="email"
          />
        </mat-form-field>
      </div>

      <div
        [formGroup]="addressForm"
        class="fx-layout-row fx-content-start fx-items-start fx-gap-4"
      >
        <mat-form-field class="fx-grow-1">
          <mat-label>{{ "ADDRESS" | translate }}</mat-label>
          <input
            [placeholder]="'ADDRESS' | translate"
            formControlName="street"
            matInput
          />
        </mat-form-field>
        <mat-form-field style="width: 200px">
          <mat-label>{{ "POSTAL_CODE" | translate }}</mat-label>
          <input
            [formControl]="postalCodeControl"
            [matAutocomplete]="postalCodeAuto"
            [placeholder]="'POSTAL_CODE' | translate"
            matInput
          />
          <mat-autocomplete
            #postalCodeAuto="matAutocomplete"
            (optionSelected)="onChangePostalCode($event)"
            [autoActiveFirstOption]="true"
          >
            @for (city of filteredPostalCodes; track $index) {
            <mat-option [value]="city" class="option-line">
              {{ city.code }} - {{ city.location }}
            </mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field style="width: 200px">
          <mat-label>{{ "CITY" | translate }}</mat-label>
          <input
            [formControl]="cityControl"
            [matAutocomplete]="cityAuto1"
            [placeholder]="'CITY' | translate"
            matInput
          />
          <mat-autocomplete
            #cityAuto1="matAutocomplete"
            (optionSelected)="onChangePostalCode($event)"
            [autoActiveFirstOption]="true"
          >
            @for (city of filteredPostalCodes; track $index) {
            <mat-option [value]="city" class="option-line">
              {{ city.location }}
            </mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div
        class="fx-layout-row fx-content-start fx-items-end fx-gap-4"
        [formGroup]="medicalHistoryForm"
      >
        <mat-form-field class="fx-grow-1">
          <mat-label>{{ "ADDITIONAL_PATIENT_HISTORY" | translate }}</mat-label>
          <input
            [placeholder]="'ADDITIONAL_PATIENT_HISTORY' | translate"
            formControlName="additionalPatientHistory"
            matInput
          />
        </mat-form-field>
      </div>

      <div
        class="fx-layout-row fx-content-start fx-items-end fx-gap-4"
        style="margin-bottom: 12px"
      >
        <div class="fx-layout-column fx-content-space-between">
          <h5 style="margin: 0 8px">
            {{ "PATIENT_CLASS" | translate }}
          </h5>

          <mat-radio-group
            class="fx-layout-row fx-content-start fx-items-end"
            formControlName="patientClassId"
          >
            @for (option of patientClasses; track $index) {
            <mat-radio-button class="fx-grow-1" [value]="option.id">
              {{ option.description }}
            </mat-radio-button>
            }
          </mat-radio-group>
        </div>

        <span class="fx-grow-1"></span>

        <div class="fx-layout-column fx-content-space-between">
          <h5 style="margin: 0 8px">{{ "DEBITER" | translate }}</h5>

          <mat-radio-group
            class="fx-layout-row fx-content-start fx-items-end"
            formControlName="debiter"
          >
            @for (option of debiters; track $index) {
            <mat-radio-button class="fx-grow-1" [value]="option">
              {{ (option === "PATIENT" ? "SELF_PAYMENT" : option) | translate }}
            </mat-radio-button>
            }
          </mat-radio-group>
        </div>
      </div>
    </mat-expansion-panel>
    @if (generalSetting?.billingRequired && patientForm.get('debiter').value !==
    'PATIENT') {
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon fontIcon="mdi-bank-check" fontSet="mdi"></mat-icon>
          {{ "INSURANCE" | translate }}
        </mat-panel-title>
        <mat-panel-description>
          {{ insuranceForm.get("organismName").valueChanges | async }}
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div
        class="fx-layout-row-nowrap fx-content-start fx-items-center fx-gap-4"
        [formGroup]="insuranceForm"
      >
        <mat-form-field style="width: 384px">
          <mat-label>{{ "INS_NAME" | translate }}</mat-label>
          <input
            [matAutocomplete]="organismAuto"
            [placeholder]="'INS_NAME' | translate"
            aria-label="Insurance organism"
            formControlName="organismName"
            matInput
            [required]="patientForm.get('debiter').value !== 'PATIENT'"
            type="text"
          />
          <mat-autocomplete
            #organismAuto="matAutocomplete"
            (optionSelected)="onSelectOrganism($event)"
            [autoActiveFirstOption]="true"
          >
            @for (organism of filteredOrganisms | async; track $index) {
            <mat-option [value]="organism.name"
              >{{ organism.name }}
            </mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ "COVER_NUMBER" | translate }}</mat-label>
          <input
            [placeholder]="'COVER_NUMBER' | translate"
            formControlName="coverNumber"
            matInput
          />
        </mat-form-field>

        <mat-form-field class="fx-grow-1">
          <mat-label>{{ "TARIFF" | translate }}</mat-label>
          <mat-select
            [placeholder]="'TARIFF' | translate"
            (selectionChange)="changeTariff($event)"
            [(ngModel)]="tariffId"
            [ngModelOptions]="{ standalone: true }"
          >
            @for (tariff of tariffs; track $index) {
            <mat-option [value]="tariff.id">{{ tariff.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>

        @switch (convType) { @case ('PEC') { @if (patientSelected) {
        <mat-form-field class="fx-grow-1">
          <mat-label>{{ "PEC_REF" | translate }}</mat-label>
          <mat-select
            [placeholder]="'PEC_REF' | translate"
            (selectionChange)="onSelectPEC($event)"
            formControlName="pecName"
          >
            @for (pec of organismPecs; track $index) {
            <mat-option [value]="pec">
              {{ splitAndGetByIndex(pec, 1) }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
        } } @case ('THIRD_PARTY_PAYMENT') {
        <mat-form-field class="fx-grow-1">
          <mat-label>{{ "CONVENTION" | translate }}</mat-label>
          <mat-select
            (selectionChange)="onChangeConvention($event)"
            [placeholder]="'CONVENTION' | translate"
            formControlName="conventionName"
          >
            @for (convention of organismConventions; track $index) {
            <mat-option [value]="convention.conventionName">
              {{ convention.conventionName }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
        } @default { } }
      </div>
    </mat-expansion-panel>
    }

    <div [formGroup]="examsForm">
      @for (item of examsForm.get('exams')['controls']; track item; let i =
      $index) {
      <div formArrayName="exams">
        <mat-expansion-panel
          [expanded]="i === examsForm.get('exams')['controls'].length - 1"
          [formGroupName]="i"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon fontIcon="mdi-radiology-box" fontSet="mdi"></mat-icon>
              {{ "EXAM" | translate }}
            </mat-panel-title>
            <mat-panel-description>
              {{ procedureCodeControl[i]?.value || "NEW_EXAM" | translate }}
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div>
            <div class="fx-layout-row fx-gap-4">
              <mat-form-field class="fx-grow-1">
                <mat-label>{{ "PROCEDURE_CODE" | translate }}</mat-label>
                <input
                  matInput
                  [placeholder]="'PROCEDURE_CODE' | translate"
                  required
                  [formControl]="procedureCodeControl[i]"
                  [matAutocomplete]="modelList"
                />
                <mat-autocomplete
                  #modelList="matAutocomplete"
                  [autoActiveFirstOption]="true"
                  (opened)="selected = null"
                  (optionSelected)="onChangeProcedureCode($event, item, i)"
                >
                  @for (pc of filteredProcedures[i]; track $index) {
                  <mat-option [value]="pc">
                    <div
                      class="fx-layout-row-nowrap fx-content-space-between fx-items-center fx-gap-4"
                    >
                      <span>{{ pc.description }}</span>
                      <span class="fx-grow-1"></span>

                      @if (selected !== pc.id && generalSetting.billingRequired)
                      {
                      <div
                        class="select-badge"
                        [style.color]="'#353535'"
                        style="width: 46px"
                      >
                        {{ pc.billingCodePrice || 0.0 }}
                      </div>
                      <div
                        class="select-badge"
                        [style.color]="'#353535'"
                        [style.background-color]="'#ffb364'"
                        style="width: 46px"
                      >
                        {{ pc.billingCodeDescription || "Z00" }}
                      </div>
                      } @if (selected !== pc.id) {
                      <div
                        class="select-badge"
                        [style.background]="pc.reasonForExamColor || '#444'"
                        style="width: 46px"
                      >
                        {{ pc.reasonForExamValue || "-" }}
                      </div>
                      }
                    </div>
                  </mat-option>
                  }
                </mat-autocomplete>
                <button
                  mat-icon-button
                  matIconSuffix
                  (click)="
                    $event.stopImmediatePropagation();
                    searchProcedureCode(item, i)
                  "
                >
                  <mat-icon fontSet="mdi" fontIcon="mdi-magnify"></mat-icon>
                </button>
              </mat-form-field>

              @if (generalSetting?.billingRequired) {
              <div
                class="fx-layout-row-nowrap fx-content-start fx-items-end fx-gap-4"
              >
                @if (convType === 'THIRD_PARTY_PAYMENT') {
                <div class="fx-layout-row-nowrap fx-gap-4" style="width: 156px">
                  <mat-form-field>
                    <mat-label>{{ "PAT_PART" | translate }}</mat-label>
                    <input
                      matInput
                      [placeholder]="'PAT_PART' | translate"
                      formControlName="patientPart"
                    />
                    <span class="currency-suffix" matTextSuffix>%</span>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>{{ "ORG_PART" | translate }}</mat-label>
                    <input
                      matInput
                      [placeholder]="'ORG_PART' | translate"
                      formControlName="organismPart"
                    />
                    <span class="currency-suffix" matTextSuffix>%</span>
                  </mat-form-field>
                </div>
                }

                <mat-form-field style="width: 100px">
                  <mat-label>{{ "TOTAL_AMOUNT" | translate }}</mat-label>
                  <input
                    [placeholder]="'TOTAL_AMOUNT' | translate"
                    matInput
                    type="number"
                    formControlName="totalAmount"
                    (keyup)="enterLinearTotalAmount(item, $event)"
                  />
                  <span class="currency-suffix" matTextSuffix>{{
                    currencyFormat
                  }}</span>
                </mat-form-field>

                <mat-form-field style="width: 100px">
                  <mat-label>{{ "DISCOUNT" | translate }}</mat-label>
                  <input
                    [placeholder]="'DISCOUNT' | translate"
                    matInput
                    type="number"
                    formControlName="discount"
                    (keyup)="enterLinearDiscount(item, $event)"
                  />
                  <span class="currency-suffix" matTextSuffix>{{
                    currencyFormat
                  }}</span>
                </mat-form-field>

                <mat-form-field style="width: 70px">
                  <mat-label
                    >{{ "DISCOUNT_PERCENTAGE" | translate }}
                  </mat-label>
                  <input
                    [placeholder]="'DISCOUNT_PERCENTAGE' | translate"
                    matInput
                    type="number"
                    formControlName="percentageDiscount"
                    (keyup)="enterLinearPercentageDiscount(item, $event)"
                  />
                  <span class="currency-suffix" matTextSuffix>%</span>
                </mat-form-field>
              </div>
              }
            </div>
            <div
              class="fx-layout-row fx-content-space-between fx-items-end fx-gap-4"
            >
              <mat-form-field style="width: 180px">
                <mat-label>{{ "PRIORITY" | translate }}</mat-label>
                <mat-select
                  formControlName="priorityId"
                  [placeholder]="'PRIORITY' | translate"
                >
                  @for (option of priorities; track $index) {
                  <mat-option [value]="option.id"
                    >{{ option.value }}
                  </mat-option>
                  }
                </mat-select>
              </mat-form-field>

              <mat-form-field style="width: 200px">
                <mat-label>{{ "PERFORMING_PHYSICIAN" | translate }}</mat-label>
                <mat-select
                  formControlName="performingPhysicianId"
                  [placeholder]="'PERFORMING_PHYSICIAN' | translate"
                >
                  @for (option of performingPhysicians; track $index) {
                  <mat-option [value]="option.id"
                    >{{ option.fullName }}
                  </mat-option>
                  }
                </mat-select>
              </mat-form-field>

              <mat-form-field class="fx-grow-1">
                <mat-label>{{ "REFERRING_PHYSICIAN" | translate }}</mat-label>
                <input
                  matInput
                  [placeholder]="'REFERRING_PHYSICIAN' | translate"
                  [formControl]="referringPhysicianControl[i]"
                  [matAutocomplete]="refPhyList"
                />
                <mat-autocomplete
                  #refPhyList="matAutocomplete"
                  [autoActiveFirstOption]="true"
                  (optionSelected)="onChangeReferringPhysician($event, item, i)"
                >
                  @for (user of filteredReferringPhysicians[i]; track $index) {
                  <mat-option [value]="user" class="option-line">
                    <div
                      class="fx-layout-column fx-content-center fx-items-start fx-gap-2"
                      [style.line-height.px]="14"
                      [style.font-size.px]="12"
                    >
                      <span class="fx-grow-1">
                        {{ user.fullName }}
                      </span>
                      @if (user.codeAnam) {
                      <span class="fx-grow-1">
                        Code:{{ user.codeAnam || "-" }}
                      </span>
                      }
                    </div>
                  </mat-option>
                  }
                </mat-autocomplete>
                <button
                  mat-icon-button
                  matIconSuffix
                  (click)="
                    $event.stopImmediatePropagation();
                    resetReferringPhysician(item)
                  "
                >
                  <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
                </button>
                <button
                  mat-icon-button
                  matIconSuffix
                  (click)="
                    $event.stopImmediatePropagation();
                    addReferringPhysician(item, i)
                  "
                >
                  <mat-icon fontSet="mdi" fontIcon="mdi-plus"></mat-icon>
                </button>
                <button
                  (click)="
                    $event.stopImmediatePropagation();
                    findReferringPhysician(item, i)
                  "
                  mat-icon-button
                  matIconSuffix
                >
                  <mat-icon fontIcon="mdi-magnify" fontSet="mdi"></mat-icon>
                </button>
              </mat-form-field>
              <mat-form-field style="width: 180px">
                <mat-label>{{ "PRESCRIPTION_DATE" | translate }}</mat-label>
                <input
                  [placeholder]="'PRESCRIPTION_DATE' | translate"
                  matInput
                  [matDatepicker]="prescDate"
                  formControlName="prescriptionDate"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="prescDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #prescDate></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="fx-layout-row fx-content-start fx-items-start fx-gap-4">
              <mat-form-field style="width: 180px">
                <mat-label>{{ "AET" | translate }}</mat-label>
                <mat-select
                  [placeholder]="'AET' | translate"
                  formControlName="aetId"
                >
                  @for (aet of filteredAets[i]; track $index) {
                  <mat-option [value]="aet.id">{{ aet.name }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>

              <mat-form-field style="width: 200px">
                <mat-label>{{ "TECHNICIAN" | translate }}</mat-label>
                <mat-select
                  [placeholder]="'TECHNICIAN' | translate"
                  formControlName="technicianId"
                >
                  @for (usr of technicians; track $index) {
                  <mat-option [value]="usr.id">{{ usr.fullName }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>

              <mat-form-field style="width: 180px">
                <mat-label>{{ "DATE" | translate }}</mat-label>
                <input
                  [placeholder]="'DATE' | translate"
                  matInput
                  [matDatepicker]="examDatePicker"
                  formControlName="date"
                  required
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="examDatePicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #examDatePicker></mat-datepicker>
              </mat-form-field>

              <mat-form-field style="width: 176px">
                <mat-label>{{ "TIME" | translate }}</mat-label>
                <input
                  type="time"
                  matInput
                  [placeholder]="'TIME' | translate"
                  formControlName="time"
                  required
                />
              </mat-form-field>
              <mat-form-field style="width: 136px">
                <mat-label>{{ "DURATION" | translate }}</mat-label>
                <input
                  type="number"
                  matInput
                  [placeholder]="'DURATION' | translate"
                  formControlName="duration"
                  required
                />
                <span class="currency-suffix" matTextSuffix>min</span>
              </mat-form-field>

              <button
                mat-icon-button
                [matTooltip]="'SEARCH_AVAILABILITY' | translate"
                [disabled]="!editable"
                (click)="checkAvailability(item)"
              >
                <mat-icon fontIcon="mdi-calendar-text" fontSet="mdi"></mat-icon>
              </button>
            </div>

            <div
              class="fx-layout-row fx-content-start fx-items-center fx-gap-4"
            >
              <mat-form-field style="width: 384px">
                <mat-label>{{ "TEMPLATEMODEL" | translate }}</mat-label>
                <input
                  [formControl]="templateModelCtrl[i]"
                  [matAutocomplete]="auto"
                  matInput
                  [placeholder]="'TEMPLATEMODEL' | translate"
                  type="text"
                />
                <mat-autocomplete
                  #auto="matAutocomplete"
                  [autoActiveFirstOption]="true"
                  (optionSelected)="onChangeTemplateModel($event, item, i)"
                >
                  @for (tm of filteredTemplateModels[i]; track $index) {
                  <mat-option [value]="tm" class="option-line"
                    >{{ tm.name }}
                  </mat-option>
                  }
                </mat-autocomplete>
              </mat-form-field>
              <mat-form-field class="fx-grow-1">
                <mat-label>{{ "COMMENT" | translate }}</mat-label>
                <input
                  matInput
                  [placeholder]="'COMMENT' | translate"
                  formControlName="commentsOnTheSPS"
                />
              </mat-form-field>
            </div>

            @if (!data?.ids) {
            <div
              class="fx-layout-row-nowrap fx-content-end fx-items-center fx-gap-4"
            >
              @if (i === examsForm.get('exams')['controls'].length - 1) {
              <button
                color="primary"
                mat-button
                (click)="addItem()"
                [disabled]="!editable || convType === 'PEC'"
              >
                <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
                {{ "ADD_NEW_EXAM" | translate }}
              </button>
              } @if (examsForm.get('exams')['controls'].length > 1) {
              <button mat-icon-button (click)="removeItem(i)">
                <mat-icon
                  fontSet="mdi"
                  fontIcon="mdi-delete"
                  color="warn"
                ></mat-icon>
              </button>
              }
            </div>
            }
          </div>
        </mat-expansion-panel>
      </div>
      }
    </div>

    @if (generalSetting?.billingRequired) {
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon fontIcon="mdi-credit-card-check" fontSet="mdi"></mat-icon>
          {{ "PAYMENT" | translate }}
        </mat-panel-title>
        <mat-panel-description class="total-amount-summary">
          {{ "TOTAL_AMOUNT" | translate }}: {{ paymentForm.get("due").value }}
          {{ currencyFormat }}
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div [formGroup]="paymentForm">
        <div class="fx-layout-row-nowrap fx-content-start fx-gap-4">
          <mat-form-field style="max-width: 160px">
            <mat-label>{{ "PAYMENT_DATE" | translate }}</mat-label>
            <input
              matInput
              [matDatepicker]="paymentDatePicker"
              placeholder="DD/MM/YYYY"
              formControlName="date"
              tabindex="-1"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="paymentDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #paymentDatePicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field style="max-width: 160px">
            <mat-label>{{ "DUE_DATE" | translate }}</mat-label>
            <input
              matInput
              [matDatepicker]="dueDatePicker"
              placeholder="DD/MM/YYYY"
              formControlName="dueDate"
              tabindex="-1"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="dueDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #dueDatePicker></mat-datepicker>
          </mat-form-field>
          <span class="fx-grow-1"></span>

          <mat-form-field style="width: 120px">
            <mat-label>{{ "DUE_AMOUNT" | translate }}</mat-label>
            <input
              matInput
              [placeholder]="'DUE_AMOUNT' | translate"
              formControlName="due"
              type="number"
              tabindex="-1"
            />
            <span class="currency-suffix" matTextSuffix>{{
              currencyFormat
            }}</span>
          </mat-form-field>

          <mat-form-field style="width: 100px">
            <mat-label
              >{{ "DISCOUNT_PRICE" | translate : { currency: currencyFormat } }}
            </mat-label>
            <input
              matInput
              (blur)="enterDiscount($event)"
              [placeholder]="'DISCOUNT_PRICE' | translate"
              formControlName="discount"
              type="number"
            />
            <span class="currency-suffix" matTextSuffix>{{
              currencyFormat
            }}</span>
          </mat-form-field>

          <mat-form-field style="width: 120px">
            <mat-label
              >{{ "TOTAL_AMOUNT" | translate : { currency: currencyFormat } }}
            </mat-label>
            <input
              matInput
              [placeholder]="'TOTAL_AMOUNT' | translate"
              formControlName="totalAfterDiscount"
              type="number"
            />
            <span class="currency-suffix" matTextSuffix>{{
              currencyFormat
            }}</span>
          </mat-form-field>

          <mat-form-field style="width: 120px">
            <mat-label>{{ "PAID_AMOUNT" | translate }}</mat-label>
            <input
              matInput
              [placeholder]="'PAID_AMOUNT' | translate"
              formControlName="enteredAmount"
              (keyup)="enterPaidAmount($event)"
              type="number"
            />
            <span class="currency-suffix" matTextSuffix>{{
              currencyFormat
            }}</span>
          </mat-form-field>

          <mat-form-field style="width: 126px">
            <mat-label>{{ "LEFT_AMOUNT" | translate }}</mat-label>
            <input
              matInput
              [ngModel]="leftAmount"
              [ngModelOptions]="{ standalone: true }"
              type="number"
              [placeholder]="'LEFT_AMOUNT' | translate"
              disabled
            />
            <span class="currency-suffix" matTextSuffix>{{
              currencyFormat
            }}</span>
          </mat-form-field>
        </div>

        <div
          class="fx-layout-row fx-content-space-between fx-items-center fx-gap-4"
          style="margin-bottom: 12px"
        >
          <div class="fx-layout-column-nowrap">
            <h5 style="margin: 0 8px">
              {{ "PAYER" | translate }}
            </h5>

            <mat-radio-group
              class="fx-layout-row fx-content-start fx-items-end"
              (change)="changePayer($event)"
              formControlName="payer"
            >
              @for (option of ['PATIENT', 'THIRD_PAYER', 'OTHER']; track $index)
              {
              <mat-radio-button class="fx-grow-1" [value]="option">
                {{ option | translate }}
              </mat-radio-button>
              }
            </mat-radio-group>
          </div>
          <span class="fx-grow-1"></span>

          <div class="fx-gap-4">
            @switch (payerFormControl.valueChanges | async) { @case ('OTHER') {
            <mat-form-field style="width: 160px" subscriptSizing="dynamic">
              <mat-label>{{ "PAYER_NAME" | translate }}</mat-label>
              <input
                matInput
                [placeholder]="'PAYER_NAME' | translate"
                (blur)="enterPayerName($event)"
                formControlName="otherPayer"
              />
            </mat-form-field>

            <mat-form-field
              style="width: 140px; margin-left: 4px"
              subscriptSizing="dynamic"
            >
              <mat-label>{{ "PAYER_ID" | translate }}</mat-label>
              <input
                matInput
                [placeholder]="'PAYER_ID' | translate"
                (blur)="enterPayerID($event)"
                formControlName="payerID"
              />
            </mat-form-field>
            } @default { } }
          </div>

          @if (patientForm.get('debiter').value !== 'PATIENT') {
          <mat-form-field style="width: 120px" subscriptSizing="dynamic">
            <mat-label>{{ "ORG_PART" | translate }}</mat-label>
            <input
              matInput
              [placeholder]="'ORG_PART' | translate"
              formControlName="organismPart"
              type="number"
            />
            <span class="currency-suffix" matTextSuffix>{{
              currencyFormat
            }}</span>
          </mat-form-field>

          <mat-form-field style="width: 126px" subscriptSizing="dynamic">
            <mat-label>{{ "PAT_PART" | translate }}</mat-label>
            <input
              matInput
              [placeholder]="'PAT_PART' | translate"
              formControlName="patientPart"
              type="number"
            />
            <span class="currency-suffix" matTextSuffix>{{
              currencyFormat
            }}</span>
          </mat-form-field>
          }
        </div>

        <div
          class="fx-layout-row fx-content-space-between fx-items-center fx-gap-4"
        >
          <mat-form-field class="fx-grow-1">
            <mat-label>{{ "PAYMENT_METHOD" | translate }}</mat-label>
            <mat-select
              [placeholder]="'PAYMENT_METHOD' | translate"
              formControlName="paymentMethodId"
              (selectionChange)="selectMethod($event)"
            >
              @for (item of paymentMethods; track $index) {
              <mat-option [value]="item.id">{{ item.value }}</mat-option>
              }
            </mat-select>
          </mat-form-field>

          <mat-form-field class="fx-grow-1">
            <mat-label>{{ "REFERENCE" | translate }}</mat-label>
            <input
              matInput
              [placeholder]="'REFERENCE' | translate"
              (blur)="enterReference($event)"
              formControlName="reference"
            />
          </mat-form-field>
          <mat-form-field class="fx-grow-1">
            <mat-label>{{ "BANK" | translate }}</mat-label>
            <mat-select
              [placeholder]="'BANK' | translate"
              formControlName="bankId"
              (selectionChange)="selectBank($event)"
            >
              @for (item of banks; track $index) {
              <mat-option [value]="item.id">{{ item.value }}</mat-option>
              }
            </mat-select>
            <button
              matSuffix
              mat-icon-button
              [disabled]="paymentForm.get('paymentDispense').value"
              (click)="$event.stopImmediatePropagation(); addBank()"
            >
              <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
            </button>
          </mat-form-field>
        </div>

        <mat-slide-toggle
          tabindex="-1"
          (change)="makeExempt($event)"
          formControlName="paymentDispense"
          style="font-weight: 400"
        >
          {{ "PAYMENT_DISPENSE" | translate }}
        </mat-slide-toggle>

        @if (paymentItems) {
        <div class="items-table">
          <div class="ft-row ft-header">
            <div class="ft-cell">
              {{ "CODE" | translate }}
            </div>
            <div class="ft-cell">
              {{ "DESIGNATION" | translate }}
            </div>
            <div class="ft-cell">
              {{ "PRICE" | translate }}
            </div>
            <div class="ft-cell">
              {{ "ORG_PART" | translate }}
            </div>
            <div class="ft-cell">
              {{ "PAT_PART" | translate }}
            </div>
            <div class="ft-cell">
              {{ "DISCOUNT" | translate }}
            </div>
          </div>
          @for (item of paymentItems; track $index) {
          <div class="ft-row">
            <div class="ft-cell">
              {{ item.accessionNumber }}
            </div>
            <div class="ft-cell">
              {{ item.procedureCode }}
            </div>
            <div class="ft-cell">{{ item.price }}</div>
            <div class="ft-cell">
              {{ item["organismPartPrice"] }}
            </div>
            <div class="ft-cell">
              {{ item["patientPartPrice"] }}
            </div>
            <div class="ft-cell">
              {{ item.discount }}
            </div>
          </div>
          }
        </div>
        }
      </div>
    </mat-expansion-panel>
    }

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon fontIcon="mdi-folder" fontSet="mdi"></mat-icon>
          {{ "ATTACHED_FILES" | translate }}
        </mat-panel-title>
        <mat-panel-description>
          {{ (fileElements | async)?.length }}
          {{ "FILE" | translate }}
        </mat-panel-description>
      </mat-expansion-panel-header>

      <ft-file-explorer
        [fileElements]="fileElements | async"
        [path]="currentPath"
        [patientID]="patientID"
        [modalMode]="true"
        [canNavigateUp]="canNavigateUp"
        (folderAdded)="addFolder($event)"
        (elementRemoved)="removeElement($event)"
        (navigatedDown)="navigateToFolder($event)"
        (navigatedUp)="navigateUp()"
        (uploadEvent)="afterUpload($event)"
        (elementRenamed)="renameElement($event)"
        (elementMoved)="moveElement($event)"
      >
      </ft-file-explorer>
    </mat-expansion-panel>
  </mat-accordion>
</div>

@if (saving || !ready) {
<mat-progress-bar [mode]="'buffer'" color="accent"></mat-progress-bar>
}

<div matDialogActions class="flex flex-row flex-nowrap gap-1 p-3">
  @if (generalSetting?.idScannerConfigured) {
  <button (click)="scanIDCard()" color="accent" mat-raised-button>
    {{ "SCAN_CARD" | translate }}
  </button>
  } @if (examSaved) {
  <button color="accent" mat-raised-button (click)="printTicket()">
    <mat-icon fontIcon="mdi-barcode" fontSet="mdi"></mat-icon>
    {{ "PRINT_STICKER" | translate }}
  </button>

  @if (generalSetting.billingRequired) {
  <button color="accent" mat-raised-button (click)="printPaymentReceipt()">
    <mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
    {{ "PAYMENT_RECEIPT" | translate }}
  </button>

  @if (eFactActivated()) {
  <button color="warn" mat-raised-button (click)="generateEFactUrl()">
    <mat-icon fontIcon="mdi-link" fontSet="mdi"></mat-icon>
    {{ "eFact" | translate }}
  </button>
  } } } @if (!editable) {
  <span
    class="modal-message flex-grow"
    translate="CANCEL_PAYMENT_TO_EDIT"
  ></span>
  }

  <span class="fx-grow-1"></span>
  <button (click)="cancel()" color="warn" mat-raised-button>
    {{ (examSaved ? "EXIT" : "CANCEL") | translate }}
  </button>

  @if (editable && !examSaved) {
  <button
    color="primary"
    mat-raised-button
    (click)="printAndSave()"
    [disabled]="
      saving ||
      patientForm.invalid ||
      examsForm.invalid ||
      (generalSetting?.billingRequired && paymentForm.invalid)
    "
  >
    {{ "PRINT_AND_SAVE" | translate }}
  </button>
  <button
    [disabled]="
      saving ||
      patientForm.invalid ||
      examsForm.invalid ||
      (generalSetting?.billingRequired && paymentForm.invalid)
    "
    color="primary"
    (click)="save()"
    mat-raised-button
  >
    {{ "SAVE" | translate }}
  </button>
  }
</div>
